import { render, staticRenderFns } from "./Eventos.vue?vue&type=template&id=7364a248&"
import script from "./Eventos.vue?vue&type=script&lang=js&"
export * from "./Eventos.vue?vue&type=script&lang=js&"
import style0 from "./Eventos.vue?vue&type=style&index=0&id=7364a248&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports