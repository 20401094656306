<template>
  <v-card>
    <v-card-title>
      {{ isEdit ? `Editar ${this.newItem.name}` : "Agregar evento" }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form class="mt-6" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-row class="mt-2">
              <v-col cols="6">
                <v-text-field
                  style="border-radius: 10px"
                  label="Nombre"
                  v-model="newItem.name"
                  filled
                  :rules="requerid"
                  required
                  rounded
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  style="border-radius: 10px"
                  label="Nombre del lugar"
                  filled
                  :rules="requerid"
                  required
                  v-model="newItem.venueName"
                  type="text"
                  rounded
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  style="border-radius: 10px"
                  :items="countries_"
                  filled
                  :item-value="[`.key`]"
                  rounded
                  dense
                  :rules="requerid"
                  required
                  item-text="name"
                  v-model="newItem.country"
                  label="Pais"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  style="border-radius: 10px"
                  :items="cities_"
                  filled
                  :item-value="[`.key`]"
                  rounded
                  dense
                  :rules="requerid"
                  required
                  item-text="name"
                  v-model="newItem.city"
                  label="Ciudad"
                ></v-select>
              </v-col>

              <v-col cols="6" lg="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newItem.dateStart"
                      label="Fecha inicial del evento"
                      persistent-hint
                      append-icon="fa-calendar-alt"
                      readonly
                      filled
                      :rules="requerid"
                      required
                      rounded
                      style="border-radius: 10px"
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newItem.dateStart"
                    no-title
                    :min="hoy"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" lg="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newItem.dateEnd"
                      label="Fecha final del evento"
                      persistent-hint
                      append-icon="fa-calendar-alt"
                      readonly
                      filled
                      :rules="requerid"
                      required
                      :disabled="!newItem.dateStart"
                      rounded
                      style="border-radius: 10px"
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newItem.dateEnd"
                    no-title
                    :min="newItem.dateStart"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-select
                  :items="typeaudience"
                  filled
                  item-value="value"
                  style="border-radius: 10px"
                  rounded
                  dense
                  item-text="item"
                  v-model="newItem.ratingAudience"
                  label="Tipo de audiencia"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newItem.categories"
                  :items="getCategories"
                  multiple
                  filled
                  label="Categoria de evento"
                  chips
                  item-value=".key"
                  style="border-radius: 10px"
                  rounded
                  value=".key"
                  color="primary"
                  clearable
                  item-text="fullName"
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      @click="select"
                      close
                      @click:close="removeCategory(item)"
                    >
                      <strong>{{ item.name }}</strong>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="newItem.keywords"
                  :items="newItem.keywords"
                  chips
                  clearable
                  label="Palabras Claves"
                  multiple
                  dense
                  :rules="requerid"
                  required
                  filled
                  rounded
                  style="border-radius: 10px"
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      outlined
                      @click="select"
                      @click:close="remove(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  style="border-radius: 10px"
                  label="Cantidad máxima de boletos por usuario"
                  filled
                  :rules="requerid"
                  required
                  v-model="newItem.maxLimitPerUser"
                  type="number"
                  rounded
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="user.type == 'sudo'">
                <v-text-field
                  style="border-radius: 10px"
                  label="Tiempo para habilitar escaneo de tickets (Minutos)"
                  filled
                  :rules="requerid"
                  required
                  v-model="newItem.timeBeforeEvent"
                  type="number"
                  rounded
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="4">
                <v-switch
                  dense
                  v-model="newItem.active"
                  label="Activo"
                ></v-switch>
              </v-col>

              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.ochoActive"
                  label="Activo por ocho"
                ></v-switch>
              </v-col>
              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.outstanding"
                  label="Destacado"
                ></v-switch>
              </v-col>

              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.isComingSoon"
                  label="Próximamente"
                ></v-switch>
              </v-col>

              <v-col cols="4">
                <v-switch
                  dense
                  v-model="newItem.isTicketRequired"
                  label="Ticket requerido"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  dense
                  v-model="newItem.showUpcomingTickets"
                  label="Mostrar próximas entradas"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  dense
                  v-model="newItem.isFree"
                  label="Es gratis"
                ></v-switch>
              </v-col>

              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.closed"
                  label="Cerrado"
                ></v-switch>
              </v-col>
              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.isOpen"
                  label="Esta abierto"
                ></v-switch>
              </v-col>
              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.dateValidation"
                  label="Validar horario de entradas"
                ></v-switch>
              </v-col>
              <v-col cols="4" v-if="user.type == 'sudo'">
                <v-switch
                  dense
                  v-model="newItem.isTester"
                  label="Es tester"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="actions-btn">
      <v-btn
        color="primary"
        text
        :loading="btnLoading"
        style="border-radius: 10px"
        @click="$emit('closed')"
        >cancelar</v-btn
      >
      <v-btn
        color="primary"
        :loading="btnLoading"
        style="border-radius: 10px"
        @click="isEdit ? EditUpdateEvent() : addUpdateEvent()"
        :disabled="!valid"
      >
        {{ isEdit ? "Editar" : "Agregar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "@/store/index.js";

import { LMap, LTileLayer, LIcon, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import VGeosearch from "vue2-leaflet-geosearch";
var randomstring = require("randomstring");
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "formNewEvent",
  props: ["dataEdit"],
  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LTooltip,
    VGeosearch,
  },
  data() {
    return {
      saleDateMenu: false,
      preOrderDateMenu: false,
      countries_: [],
      cities_: [],
      isEdit: false,
      tab: null,
      valid: true,
      FileImage: null,
      imageFile: null, // cover comercio
      imageBannerFile: null,
      Code: null,
      btnLoading: false,
      typeaudience: [
        {
          item: "Todo publico",
          value: "Todo publico",
        },
        {
          item: "Mayor de edad",
          value: "mayor de edad",
        },
        {
          item: "Mayores de 12 años",
          value: "Mayores de 12 años",
        },
      ],
      categories: [],
      imagedefaul: require("@/assets/templateImage.svg"),
      dialog: false,
      loading: true,
      imgSrcBanner: "",
      hoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      File: null,
      menu2: false,
      allEvents: [],
      newItem: {},
      requerid: [(v) => !!v || "Campo requerido"],
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      address: ["14.0985252", "-87.1789062"],

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 27,
      center: [47.41322, -1.219482],
      priceRange: [1, 2, 3, 4],
    };
  },
  computed: {
    ...mapState(["business", "user"]),
    bussines() {
      return this.business;
    },
    getCategories() {
      let categories = this.categories.filter((item) =>
        this.business.oViveCategories.find((e) => e == item[`.key`])
      );
      // console.debug(categories.length);
      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities_.find(
            (city) => city[`.key`] === category.city
          );
          newCategory.fullName = city ? `${city.name} - ${category.name} ` : "";
          // console.debug("categories", category.name);
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    getImage() {
      return L.icon({
        // iconUrl: require("@/assets/avatar-default.png"),
        iconUrl:
          "https://firebasestorage.googleapis.com/v0/b/el-ocho-dev-data/o/vive%2Favatar-01.png?alt=media&token=0675c14f-8569-473d-a21c-d502c9bff405",
        shadowUrl: require("@/assets/map-marker.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },
  watch: {
    dataEdit(e) {
      this.newItem = e;
    },
  },
  methods: {
    ...mapActions([
      "_searchTerm",
      "Alert_",
      "addEventSelection",
      "visibleSearch",
    ]),
    centerUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    positionUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    updatePictures(eventId) {
      return new Promise(async (resolve, reject) => {
        let bucket = `${process.env.VUE_APP_EL_OCHO_BK}-vive`;
        if (bucket) {
          let uploadBanner = new Promise((resolve, reject) => {
            if (this.imageFile) {
              fb.app()
                .storage(bucket)
                .ref()
                .child(
                  `cover/${eventId}/event.${this.imageFile.type.split("/")[1]}`
                )
                .put(this.imageFile)
                .then((res) => {
                  // console.debug("resp", res);
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });

          await uploadBanner;
          resolve("success");
        }
      });
    },
    getGeoPoint(id) {
      return new Promise((resolve, reject) => {
        let data = {
          latitude: this.address[0],
          longitude: this.address[1],
          collection: "events",
          collectionId: id,
        };
        var httpGeneralUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpGeneralUpdateGeoPoint");
        httpGeneralUpdateGeoPoint(data)
          .then((result) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    selectImage: function (imageType) {
      this.imageType = imageType;
      this.$refs.input.click();
    },
    generateCode() {
      if (this.newItem.name) {
        this.newItem.code = `${this.newItem.name
          .toUpperCase()
          .substr(0, 2)}-${randomstring.generate(6).toUpperCase()}`;
      } else {
        this.newItem.code = `EV-${randomstring.generate(6).toUpperCase()}`;
      }
    },
    async getAllEvent() {
      if (this.bussines) {
        await this.$binding(
          "allEvents",
          db.collection("events").where("business", "==", this.bussines[`.key`])
        );
        this.loading = false;
      }
    },
    compareByFullName(a, b) {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    },
    setImage(e) {
      this.imageFile = e.target.files[0];
      // console.debug(this.imageFile);
      this.imageBannerFile = e.target.files[0];

      if (e.target.files[0].type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrcBanner = event.target.result;
      };

      reader.readAsDataURL(this.imageFile);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async addUpdateEvent() {
      this.generateCode();
      this.btnLoading = true;
      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const newEvent = this.newItem;

        newEvent.deleted = false;
        newEvent.createdAt = new Date();
        newEvent.createdBy = this.user[`.key`];
        newEvent.business = this.bussines[`.key`];
        newEvent.tags = [];
        newEvent.isTester = newEvent.isTester || false;
        newEvent.ticketValidations = true;

        db.collection("events")
          .add(newEvent)
          .then(async (ref) => {
            // await this.getGeoPoint(ref.id);
            // console.debug(newEvent);
            // console.debug(ref);
            // await this.updatePictures(ref.id);
            this.Alert_({
              text: "Evento creado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.btnLoading = false;
              this.$emit("closed");
            }, 2000);
          })
          .catch(() => {
            this.Alert_({
              text: "Ocurrió un error al crear el Evento",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnLoading = false;
            this.$emit("closed");
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnLoading = false;
      }
    },
    async EditUpdateEvent() {
      this.btnLoading = true;
      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const editEvent = {
          name: this.newItem.name,
          venueName: this.newItem.venueName,
          dateStart: this.newItem.dateStart,
          dateEnd: this.newItem.dateEnd,
          ratingAudience: this.newItem.ratingAudience,
          keywords: this.newItem.keywords,
          description: this.newItem.description,
          instructions: this.newItem.instructions,
          country: this.newItem.country,
          city: this.newItem.city,
          maxLimitPerUser: Number(this.newItem.maxLimitPerUser),
          currentPercentage: Number(this.newItem.currentPercentage),
          code: this.newItem.code,
          isComingSoon: this.newItem.isComingSoon,
          isOpen: this.newItem.isOpen,
          isFree: this.newItem.isFree,
          isTicketRequired: this.newItem.isTicketRequired,
          showUpcomingTickets: this.newItem.showUpcomingTickets,
          ochoActive: this.newItem.ochoActive,
          closed: this.newItem.closed,
          isTester: this.newItem.isTester || false,
          active: this.newItem.active,
          modifiedAt: new Date(),
          modifiedBy: this.user[`.key`],
          outstanding: this.newItem.outstanding,
        };
        // console.debug(editEvent);

        db.collection("events")
          .doc(this.dataEdit[`.key`])
          .update(editEvent)
          .then(async (ref) => {
            // await this.getGeoPoint(this.dataEdit[`.key`]);
            // console.debug(editEvent);

            if (this.imageFile) {
              await this.updatePictures(this.dataEdit[`.key`]);
            }

            this.Alert_({
              text: "Evento Actualizado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.btnLoading = false;
              this.$emit("closed");
            }, 2000);
          })
          .catch((error) => {
            // console.debug(error);
            this.Alert_({
              text: "Ocurrió un error al editar el evento",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnLoading = false;
            this.$emit("closed");
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnLoading = false;
      }
    },

    generateTimestamp(data, source) {
      if (data) {
        let date = data.split("-");
        console.log(date);
        return fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(source == "from" ? "00" : "06"),
            parseInt(source == "from" ? "01" : "00"),
            parseInt("00")
          )
        );
      } else {
        return null;
      }
    },
    remove(item) {
      this.newItem.keywords.splice(this.newItem.keywords.indexOf(item), 1);
    },
    removeCategories(item) {
      this.newItem.categories.splice(this.newItem.keywords.indexOf(item), 1);
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$binding(
        "countries_",
        db
          .collection("countries")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );
      await this.$binding(
        "cities_",
        db
          .collection("cities")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );

      if (this.dataEdit) {
        this.isEdit = true;
        this.imgSrcBanner = this.dataEdit.cover.original;
        this.newItem = JSON.parse(JSON.stringify(this.dataEdit));
      } else {
        this.isEdit = false;
      }
      await this.$binding(
        "categories",
        db.collection("oViveCategories").where("deleted", "==", false)
      ).then(() => {
        // this.categories = this.categories.map((item) => {
        //   return {
        //     value: item[".key"],
        //     text: item.name,
        //     city: item.city,
        //   };
        // });
        this.loading = false;
      });
    });
  },
};
</script>
<style lang="scss">
.theme--dark.v-tabs-items {
  background-color: #1e1e1e00 !important;
}
.upload {
  display: none;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
  color: #000 !important;
}
</style>
