<template>
  <div class="General">
    <v-img
      v-if="businessDB"
      style="border-radius: 10px"
      :src="imagedefaul"
      height="200px"
      class="flex-center mb-4"
    >
      <template>
        <div class="img-text-perfil">
          <v-img
            :src="imagedefaulLogo"
            class="img-perfil"
            width="80px"
            height="80px"
            style="border-radius: 50%"
            elevation-10
          >
          </v-img>
          <h2 style="color: #fff">{{ businessDB.shortName }}</h2>
       
         
        </div>
        <div class="btn-acti" style="position: absolute; right: 10px; bottom: 10px;" v-if="['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)">
          <v-btn
        color="info"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialogEditComer = true"
        >Editar comercio</v-btn
      >
      <v-btn
        color="success"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialogBank = true"
        > Información de pago</v-btn
      >
        </div>
      </template>
    </v-img>
    <!-- header de titulo y acciones -->
    <headerLayaut subtitle="Todos los eventos">
   
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        v-if="['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialog = true"
        >Agregar evento</v-btn
      >
    </headerLayaut>
    <!-- contenido de la pagina -->
    <div class="body-template">
      <v-data-table
        :headers="headers"
        :items="allEvents"
        sort-by="createdAt"
        fixed-header
        :search="$store.state.search"
        :loading="loading"
        sort-desc
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }"
      >
        <template v-slot:[`item.code`]="{ item }">
          <v-chip color="gray" @click="copyToClipboard(item.code) ">
            <span>
              {{ item.code }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.description`]="{ item }">
         
         
        <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
     
           <p   v-bind="attrs"
          v-on="on" class="short-text">
              {{ item.description }}
            </p>
      </template>
      <p style="width:300px">{{item.description}}</p>
    </v-tooltip>
        </template>
        <template v-slot:[`item.ratingAudience`]="{ item }">
          <v-chip color="gray">
            <span>
              {{ item.ratingAudience }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-switch
          :disabled="!['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)"
            @change="
              switchControlChanged(
                { active: item.active },
                item['.key'],
                item,
                'active'
              )
            "
            v-model="item.active"
          >
          </v-switch>
        </template>
        <template v-slot:[`item.ochoActive`]="{ item }">
          <v-switch
            dense
            :disabled="!['sudo','viveSudo','viveLesserAdmin','viveOperations', 'viveBusiness'].includes(user.type)"
            @change="
              switchControlChanged(
                { ochoActive: item.ochoActive },
                item['.key'],
                'ochoActive'
              )
            "
            v-model="item.ochoActive"
          ></v-switch>
        </template>
        <template v-slot:[`item.outstanding`]="{ item }">
          <v-switch
            dense
            :disabled="!['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)"
            @change="
              switchControlChanged(
                { outstanding: item.outstanding },
                item['.key'],
                'outstanding'
              )
            "
            v-model="item.outstanding"
          ></v-switch>
        </template>
        <template v-slot:[`item.isComingSoon`]="{ item }">
          <v-switch
            dense
            
            v-model="item.isComingSoon"
            :disabled="!['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)"
            @change="
              switchControlChanged(
                { isComingSoon: item.isComingSoon },
                item['.key'],
                'isComingSoon'
              )
            "
          ></v-switch>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-tooltip left  v-if="['viveFinance','viveSales'].includes(user.type)">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="SelecEvents(item)"
                color="#00cdbc"
              >
                <v-icon small dark>fa-solid fa-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver {{ item.name }}</span>
          </v-tooltip>
          <v-tooltip left  v-if="['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="SelecEvents(item)"
                color="info"
              >
                <v-icon small dark>fa-solid fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Editar {{ item.name }}</span>
          </v-tooltip>
          <v-tooltip left  v-if="['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="selectedDeleted(item)"
                color="#d42828"
              >
                <v-icon small dark>fa-solid fa-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Eliminar {{ item.name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <!-- add edit Business -->
    <v-dialog v-model="dialogEditComer"  v-if="dialogEditComer" scrollable max-width="800">
      <formBusiness

        :dataEdit="businessDB"
        @cancel="dialogEditComer = false"
      />
    </v-dialog>
    <!-- add edit Business -->
    <v-dialog v-model="dialogBank" v-if="dialogBank" scrollable max-width="800">
      <infoBank
        :dataEdit="businessDB"
        @cancel="dialogBank = false"
      />
    </v-dialog>
    <!-- add nuevo evento -->
    <v-dialog v-model="dialog" scrollable max-width="800">
      <formNewEvent
        v-if="dialog"
        :dataEdit="eventEditValue"
        @closed="(dialog = !dialog), (eventEditValue = null)"
      />
    </v-dialog>

    <!-- eliminar -->
    <v-dialog
      v-model="deleteConfirm"
      v-if="deleteConfirm"
      min-width="350px"
      max-width="400px"
    >
      <v-card class="pa-2">
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          small
          absolute
          @click="deleteConfirm = false"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
        <v-card-title class="pr-4">¿ Eliminar Evento ?</v-card-title>
        <v-card-subtitle class="mt-2">
          {{ deletedkey.name }}
        </v-card-subtitle>

        <v-card-actions class="flex-end">
          <v-btn color="primary" :loading="btnLoading" @click="deletedEvent">
            Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store/index.js";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import infoBank from '../views/sudo/event/infoBank.vue'
import headerLayaut from "@/components/header-layaut.vue";
import formBusiness from "@/views/sudo/businesses/new-business.vue";
import formNewEvent from "@/components/formNewEvent.vue";
export default {
  name: "Event",

  components: {
    headerLayaut,
    formNewEvent,
    formBusiness,
    infoBank
  },
  data() {
    return {
      deleteConfirm: false,
      valid: true,
      businessDB: [],
      btnLoading: false,
      dialogEditComer: false,
      dialogBank: false,
      eventEditValue: null,
      deletedkey: null,
      imagedefaul: require("@/assets/templateImage.svg"),
      imagedefaulLogo: require("@/assets/templateImage.svg"),
      dialog: false,
      loading: true,
      hoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      menu2: false,
      allEvents: [],
      newItem: {
        active: false,
        deleted: false,
        ochoActive: false,
        name: "",
        code: "",
        cover: {
          loading: "",
          original: "",
        },
        country: "",
        city: "",
        business: "",
        categories: [],
        maxLimitPerUser: 0,
        deletedBy: "",
        dateStart: "",
        dateEnd: "",
        ratingAudience: "",
        description: [],
        currentPercentage: 0,
        extraFees: [],
        dialogEditComer: false,
        closed: false,
        isComingSoon: false,
        isOpen: false,
        isFree: false,
        isTicketRequired: true,
        showUpcomingTickets: false,
        geoAddress: [],
        venueName: "",
        keywords: [],
        Images: [],
      },
      requerid: [(v) => !!v || "Name is required"],
      headers: [
        { text: "Código", value: "code" },

        { text: "Nombre", value: "name" },
        { text: "Descripción", value: "description" },
        { text: "Tipo de Audiencia", value: "ratingAudience" },
        { text: "Lugar evento", value: "venueName" },
        { text: "inicia", value: "dateStart" },
        { text: "finaliza", value: "dateEnd" },
        // { text: "Activo por Ocho", value: "ochoActive" },
        { text: "Destacado", value: "outstanding" },
        { text: "Próximamente", value: "isComingSoon" },
        { text: "Activo", value: "active" },
        { text: "Acciones", value: "options" },
      ],
    };
  },
  computed: {
    ...mapState(["business", "user", "selectedEvent"]),
  },
  methods: {
    ...mapActions([
      "_searchTerm",
      "Alert_",
      "addEventSelection",
      "visibleSearch",
    ]),
    // var code = "ORD-" + makeid(4) + parseInt(Math.floor(Math.random() * 99) + 1);
    selectedDeleted(id) {
     // console.debug(id);
      this.deletedkey = id;
      this.deleteConfirm = true;
    },
    deletedEvent() {
      if (this.deletedkey) {
        db.collection("events")
          .doc(this.deletedkey[`.key`])
          .update({
            deletedAt: new Date(),
            deletedBy: this.user[`.key`],
            deleted: true,
            active: false,
          })
          .then(() => {
            this.Alert_({
              text: "Evento eliminado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "info",
            });
            this.deleteConfirm = false;

            this.addEventSelection(null);
            this.deletedkey = null;
          });
      } else {
        this.deleteConfirm = false;
        this.deletedkey = null;
      }
    },
    SelecEvents(item) {
      this.addEventSelection(null);
      setTimeout(() => {
        this.addEventSelection(item);

        this.Alert_({
          text: "Evento seleccionado y activo en panel izquierdo",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "#00cdbc",
        });

        if (['viveFinance','viveSales'].includes(this.user.type)) {
          
          this.$router.push("/ticketsSold");
        }else{
          this.$router.push("/general");

        }
      }, 1000);
    },
    async getAllEvent() {
      if (this.business) {
       // console.debug(this.business[`.key`]);
        await this.$binding(
          "allEvents",
          db
            .collection("events")
            .where("business", "==", this.business[`.key`])
            .where("deleted", "==", false)
        );
        this.loading = false;
      }
    },

    SelectEdit(event) {
     // console.debug(event);
      this.eventEditValue = event;
      this.dialog = true;
    },
     copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
           this.Alert_({
              text:  "Copiado al portapapeles",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
        
        },
        (err) => {
          //console.error("Async: Could not copy text: ", err);
        }
      );
    },
    switchControlChanged(data, eventId, business, field) {
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && eventId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.user[`.key`];
       // console.debug(data);
        db.collection("events")
          .doc(eventId)
          .update(data)
          .then(() => {
            this.Alert_({
              text: "Información del evento actualizada correctamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
          })
          .catch((err) => {
           console.log(err);
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });

            business[field] = !business[field];
          });
      }
    },
  },
  async mounted() {
    this._searchTerm("Eventos");
    this.getAllEvent();
    this.visibleSearch(true);
    await this.$binding(
      "businessDB",
      db.collection("businesses").doc(this.business[`.key`])
    );

    this.imagedefaulLogo = this.businessDB.logo
      ? this.businessDB.logo.original
      : require("@/assets/templateImage.svg");
    this.imagedefaul = this.businessDB.cover
      ? this.businessDB.cover.original
      : require("@/assets/templateImage.svg");
  },
};
</script>

<style lang="scss" >
.short-text{
      width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.img-text-perfil {
  position: absolute;
  left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  bottom: 10px;
  .img-perfil {
    border: solid 3px #fff;
    // box-shadow: #00cdbc 0px 0px 10px;
  }
  h2 {
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
    margin-left: 20px;
    margin-bottom: 10px;
    z-index: 20;
  }
}
.flex-end {
  display: flex;
}
</style>
