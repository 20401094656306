<template>
    <v-card>
        <v-snackbar :color="snackbarColor" top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

        <v-card-title>
            Información de pago
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-form ref="form">
                <v-row class="pa-0 ma-0">
                    <v-col cols="12" sm="6" md="6">
               
                        <v-text-field rounded filled style="border-radius: 10px;" v-mask="`##############`"
                        label="RTN"
                            :rules="[rules.required, rules.numbers]" placeholder="Ingrese el RTN"
                            v-model="business.tin" />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Razón social" rounded filled style="border-radius: 10px;" type="text" :rules="[rules.required, rules.letters]"
                            placeholder="Ingrese la razón social" v-model="business.tinName" />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete label="Nombre del banco" v-model="business.bankAccounts[0].bank" :items="banks" :rules="[rules.required]"
                            placeholder="Seleccione el banco" filled style="border-radius: 10px;" item-text="name" item-value="value" rounded />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                       
                        <v-select label="Tipo de cuenta" v-model="business.bankAccounts[0].type" :items="accountsType"
                            placeholder="Seleccione tipo de cuenta" filled style="border-radius: 10px;" :rules="[rules.required]" item-text="name"
                            item-value="value" rounded />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Nombre de la Cuenta" type="text" rounded :rules="[rules.required]" filled style="border-radius: 10px;"
                            placeholder="Ingrese el nombre de la cuenta" v-model="business.bankAccounts[0].name" />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                     
                        <v-text-field label=" Número de la Cuenta" type="number" rounded filled style="border-radius: 10px;" :rules="[rules.required, rules.numbers]"
                            placeholder="Ingrese el número de la cuenta"
                            messages="(Sin espacios o guiones)"
                            v-model.number="business.bankAccounts[0].number" />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-select v-model="business.bankAccounts[0].currency" :items="currency"
                          label="Moneda"   placeholder="Seleccione la moneda" filled style="border-radius: 10px;" :rules="[rules.required]" item-text="name"
                            item-value="value" rounded />
                    </v-col>

                  
                </v-row>
            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text width="200px" @click="$emit('cancel')">Cancelar</v-btn>
            <v-btn :loading="loading"  width="200" @click="updateAccounts" color="primary"
                            dark>Guardar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
export default {
    name: 'infoBank',
    props:['dataEdit'],
    data() {
        return {
            loading: true,
            saving: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "red",
            rules: {
                letters: (v) =>
                    /[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/.test(v) ||
                    "Solo es permitido el ingreso de letras",
                required: (value) => !!value || "Obligatorio",
                array: (value) => (value && value.length) || "Obligatorio",

                numbers: (v) =>
                    /^[0-9]+$/.test(v) || "Solo es permitido el ingreso de números",

                min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
                email: (v) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(v) || "Correo Invalído";
                },
            },
            business: {},
            accountsType: [
                { name: "Ahorro", value: "savings" },
                { name: "Cheques", value: "checkings" },
            ],
            currency: [
                { name: "Lempiras (L)", value: "lps" },
                { name: "Dólares ($)", value: "usd" },
            ],
            banks: [
                { value: 1, name: "Banco Central de Honduras" },
                { value: 2, name: "Banco Atlántida" },
                { value: 3, name: "Banco Ficohsa CB" },
                { value: 4, name: "Banco de Trabajadores" },
                { value: 5, name: "Banco de Occidente" },
                { value: 6, name: "Banco de Honduras" },
                { value: 7, name: "Banco Hondureño del Cafe (Banhcafe)" },
                { value: 8, name: "Banco del País (Banpais)" },
                { value: 9, name: "Banco Lafise" },
                { value: 10, name: "Banco Ficensa" },
                { value: 11, name: "BAC Honduras" },
                { value: 12, name: "Banco Promerica" },
                { value: 13, name: "Banco Ficohsa" },
                { value: 14, name: "Banco Davivienda" },
                { value: 15, name: "BanRural" },
                { value: 16, name: "Banco Azteca" },
                { value: 17, name: "Banco Nacional de Desarrollo Agricola( Banadesa)" },
                { value: 18, name: "Financiera CrediQ" },
                { value: 19, name: "Banco Popular" },
            ],
        };
    },
    computed: {
        ...mapState(["selectedBusiness"]),
    },
    mounted(){
        this.getBusinessData()
    },
    methods: {
        async getBusinessData() {
      this.loading = true;
      this.business = Object.assign({},  this.dataEdit)
  

      if (!this.business.bankAccounts) {
        this.business.bankAccounts = [
          {
            name: "",
            bank: "",
            currency: "",
            number: "",
            type: "",
          },
        ];
      }
      this.loading = false;
    },
        updateAccounts() {
            if (this.$refs.form.validate()) {
                this.loading = true;
            
                db.collection("businesses")
                    .doc(this.dataEdit[".key"])
                    .update({
                        bankAccounts: this.business.bankAccounts,
                        modifiedAt: new Date(),
                        modifiedBy: this.$store.state.user[".key"],
                        tin: this.business.tin ? this.business.tin : "",
                        tinName: this.business.tinName ? this.business.tinName : "",
                    })
                    .then(() => {
                        this.snackbarText = `Cuenta bancaria actulizada exitosamente`;
                        this.snackbar = true;
                        this.loading = false;
                        this.snackbarColor = "green";

                     this.$emit('cancel');
                    })
                    .catch((err) => {
                        this.snackbarText = `Ocurio un error: ${err}`;
                        this.snackbar = true;
                        this.loading = false;
                        this.snackbarColor = "red";
                        this.$emit('cancel');
                    });
            } else {
                this.snackbarText = "Ingrese los campos obligatorios.";
                this.snackbar = true;
                this.snackbarColor = "red";
            }
        },
    }
}



</script>